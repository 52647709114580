/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  padding: 5px 10px;
  cursor: pointer;
}

main {
  padding: 20px;
  flex: 1;
}

/* Responsive adjustments */
@media (min-width: 1100px) {
  main {
    max-width: 1200px;
    margin: 0 auto;
  }

  section {
    padding: 20px;
  }

  nav ul li {
    display: inline-block;
  }
}
